<template>
  <v-container
    id="users"
    fluid
    tag="section"
  >
    <div
      v-if="loading"
      class="text-center"
    >
      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
    </div>

    <base-material-card
      color="indigo"
      icon="mdi-account-details"
      inline
      class="px-5 py-3"
      hover-reveal
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light mt-2">
          User List
        </div>
      </template>

      <v-row class="align-center">
        <v-spacer />

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          label="Search"
          hide-details
          style="max-width: 250px; margin-bottom: 5px; margin-right: 12px"
        />

        <v-btn
          color="primary"
          class="mx-3"
          @click="addDialog = true"
        >
          <v-icon left>
            mdi-account-plus
          </v-icon>
          Add User
        </v-btn>
      </v-row>

      <v-divider class="mt-3" />

      <v-data-table
        :headers="headers"
        :items="users"
        :search.sync="search"
        multi-sort
        sort-desc
      >
        <template v-slot:item.username="{ item }">
          {{ getUsername(item.username) }}
        </template>

        <template v-slot:item.permissions="{ item }">
          <v-menu
            open-on-click
            bottom
            offset-y
            allow-overflow
            max-height="304px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                v-bind="attrs"
                min-width="100%"
                class="align-items: justify-center"
                v-on="on"
                @click="showListAccess(item)"
              >
                {{ getPermissions(item.permissions).join(', ').slice(0, 50) }}
                <v-spacer />
                <v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(p, index) in itemPermissions"
                :key="index"
                link
              >
                <v-list-item-title>{{ p }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-btn
                class="ma-2"
                color="green"
                outlined
                fab
                x-small
                v-bind="attrs"
                v-on="on"
                @click="toUserDetail(item)"
              >
                <v-icon>mdi-format-list-bulleted-square</v-icon>
              </v-btn>
            </template>
            <span>Key list</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-btn
                class="ma-2"
                outlined
                fab
                x-small
                v-bind="attrs"
                v-on="on"
                @click="deleteUser(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete user</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>

    <!-- Add User -->
    <template>
      <v-dialog
        v-model="addDialog"
        max-width="400px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Add User</span>
          </v-card-title>

          <v-card-text>
            <v-text-field
              ref="username"
              v-model="newUser.username"
              label="Username *"
              prepend-icon="mdi-account-outline"
              :rules="[rules.username.notEmpty, rules.username.characters]"
              :counter="20"
            />

            <v-text-field
              ref="fullName"
              v-model="newUser.fullName"
              label="Full Name *"
              prepend-icon="mdi-account-tie-outline"
              :rules="[rules.fullName.notEmpty, rules.fullName.characters, rules.fullName.length]"
              :counter="30"
            />

            <v-text-field
              ref="keyName"
              v-model="newUser.keyName"
              label="Key Name *"
              prepend-icon="mdi-key-outline"
              :rules="[rules.keyName.notEmpty]"
              :counter="40"
            />

            <v-radio-group
              v-model="newUser.regType"
              row
            >
              <v-radio
                label="FIDO2 Key register"
                value="FIDO2"
              />
              <v-radio
                label="QR register"
                value="QRCode"
              />
            </v-radio-group>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-slide-x-reverse-transition>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="my-0"
                    v-bind="attrs"
                    @click="resetForm"
                    v-on="on"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Reset</span>
              </v-tooltip>
            </v-slide-x-reverse-transition>

            <v-btn
              color="blue darken-1"
              width="100"
              @click="close"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              width="100"
              @click="addUser"
            >
              Add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showQR"
        persistent
        max-width="350px"
      >
        <v-card
          color="rgba(255, 255, 255)"
          outlined
        >
          <v-card-text style="color: #333333">
            <div class="text-h3 subtitle-1 font-weight-light text-center">
              Scan the QR code
            </div>

            <v-img :src="imgqr" />

            <v-row class="text-center">
              <v-col
                md="4"
                sm="4"
                lg="4"
              />
              <v-col
                md="4"
                sm="4"
                lg="4"
              >
                <v-btn
                  width="100%"
                  color="success"
                  @click="cancelQR"
                >
                  Cancel {{ countDown }}
                </v-btn>
              </v-col>
              <v-col
                md="4"
                sm="4"
                lg="4"
              />
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { preformatMakeCredReq, publicKeyCredentialToJSON } from '../../../utils/helpers'
  import LocalStorageService from '../../../utils/auth'
  const localStorageService = LocalStorageService.getService()
  let msgServer

  export default {
    name: 'DashboardExtendedTables',

    data: () => ({
      loading: false,
      // table data
      users: [],
      headers: [
        { text: 'Username', value: 'username' },
        { text: 'Created At', value: 'CreatedAt' },
        { text: 'Permissions', value: 'permissions' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      search: undefined,
      itemPermissions: [],

      // add
      formHasErrors: false,
      addDialog: false,
      newUser: {
        username: '',
        fullName: '',
        keyName: '',
        regType: 'FIDO2',
      },
      countDown: 40,
      showQR: false,
      imgqr: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADIAQMAAACXljzdAAAABlBMVEX///8AAABVwtN+AAAD10lEQVR4nOzYPY4sJxcG4JevPokM7MxByeVlENhiS1Whk67qDUxv6QRXYhtl1QYgI0C8Fv3jO3fslu4wE16ibj39Q8PhnEPjx/j0ocnAwAsZsNI4bl6RsVegAtyUxAZuWpFVK8HcK1MSCman5LgwYnjxKpQPSIAfZw8/2hzdqMIHpbp9zdBMwXiePyRQoWI3LgUsQYVhc69W591CBuOOF5fCuNwffd2590obicfmqsfJA+OsX8XPu2U4i81DRdWkJOEG+CP2ylF9dSO08cc5MBR44Lqinyu6WFa3w6sWo9WPpzaX2CkY4Y0vxkUPo40vKhg99IrGmqMfLjSaifC7Fct97hSwwnJfg9EFsFJsNh7dMiVWvZ88hZszfl/E8tg6RZdZpzwuAXq60EpZA9xtDXpkXIU8LjR+Stn40dLgA2JgNIymDC8arsywYdh6hecQcbz46MoabICVqse5V8aTgx/hkwwbUsbJqYxeAVZaYmFLjNej0gIInaIZffUwrrr95JKMgLnvz0Ou7//5uwTHWWwYWO7F7Zq072f7U2WcXQRjm8EqE6czLW8Z6asMFU/E5m9EwwrD1DZ+ilCE8ffz80r8/kxW+UbA9hlTKgAFP+nhnB/57ZVs/y2/vRVNspUE+UVxa685C3mP0buYp6L/JWXWFeP6ZTgTMDheEPW3ov56Jngr2JeQMmzB0mbtRoMU3sifT+WPN6IZdXRD/D+ma4EZgagLOgXjSuCI7iq/ArMmb/vzkP89FcU30uqGCkPKaGLluvmPWd9lfSrTW8HClMv8O2zbn3wkVnePqodo4omot1JmRLSuBidgEqzB/JN7H4LvlzWrMC5fHmdO5UeP1CGYXnwK+3L7HjgmMW7/sFjuxr8WXVSI7qAYHGdW1yrg4/d0CFvTSkrVbUmxr1TsFpS2uBOZ8m5JljUw32K0R1h11EeTqerqmaTec2+PXEOAFUqGFFKYzpLC7Sz0yHhq5eoSEodL642XwFYc+kRP56w4bS76smbjypJtvvfxPbLBtNuIZeuNw0R51PoeGZdscFwE7oiO5Kat3GpJh+CoUBxP7fmFSVo3KPvcKXq0Icm1CkyU6w1FhX5hdIm7ATm0grkvweCR394tgBKVyyotqEVlUnjvez9ZoGtrFlvelKqLYrz38R3Sbj1GH+3KOK5MYTc6hVuM9giUWBk2D42TgyurRH3rr3tkShL9cP1DICJqGEd+RIKSAk8ZVbvH28BwW4M+Eeh9ydFh9krKKtXvc69ASeLAbDAuUtEO+L2H7ZG2Px7QNuwnRznOQnl0T++WH6Nn/B0AAP//7hw3VCp2XD0AAAAASUVORK5CYII=',
      rules: {
        username: {
          characters: value => {
            const pattern = /^(?=[a-zA-Z0-9._]{3,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/
            return pattern.test(value) || 'Enter username between 3-10 characters, start/end with an alphanumberic and only contain alphanumberics . _ characters.'
          },
          notEmpty: v => !!v || 'Username is required',
        },
        fullName: {
          characters: value => {
            // const v = value.replace(/^\s+|\s+$/gm, '')
            const pattern = /^(?=[a-zA-Z0-9 ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\u0065-\u0309\u0065-\u0301\u0065-\u0300\u0065-\u0323\u0065-\u0303\u00EA-\u0309\u00EA-\u0301\u00EA-\u0300\u00EA-\u0323\u00EA-\u0303\u0079-\u0309\u0079-\u0301\u0079-\u0300\u0079-\u0323\u0079-\u0303\u0075-\u0309\u0075-\u0301\u0075-\u0300\u0075-\u0323\u0075-\u0303\u01B0-\u0309\u01B0-\u0301\u01B0-\u0300\u01B0-\u0323\u01B0-\u0303\u0069-\u0309\u0069-\u0301\u0069-\u0300\u0069-\u0323\u0069-\u0303\u006F-\u0309\u006F-\u0301\u006F-\u0300\u006F-\u0323\u006F-\u0303\u01A1-\u0309\u01A1-\u0301\u01A1-\u0300\u01A1-\u0323\u01A1-\u0303\u00F4-\u0309\u00F4-\u0301\u00F4-\u0300\u00F4-\u0323\u00F4-\u0303\u0061-\u0309\u0061-\u0301\u0061-\u0300\u0061-\u0323\u0061-\u0303\u0103-\u0309\u0103-\u0301\u0103-\u0300\u0103-\u0323\u0103-\u0303\u00E2-\u0309\u00E2-\u0301\u00E2-\u0300\u00E2-\u0323\u00E2-\u0303\u0045-\u0309\u0045-\u0301\u0045-\u0300\u0045-\u0323\u0045-\u0303\u00CA-\u0309\u00CA-\u0301\u00CA-\u0300\u00CA-\u0323\u00CA-\u0303\u0059-\u0309\u0059-\u0301\u0059-\u0300\u0059-\u0323\u0059-\u0303\u0055-\u0309\u0055-\u0301\u0055-\u0300\u0055-\u0323\u0055-\u0303\u01AF-\u0309\u01AF-\u0301\u01AF-\u0300\u01AF-\u0323\u01AF-\u0303\u0049-\u0309\u0049-\u0301\u0049-\u0300\u0049-\u0323\u0049-\u0303\u004F-\u0309\u004F-\u0301\u004F-\u0300\u004F-\u0323\u004F-\u0303\u01A0-\u0309\u01A0-\u0301\u01A0-\u0300\u01A0-\u0323\u01A0-\u0303\u00D4-\u0309\u00D4-\u0301\u00D4-\u0300\u00D4-\u0323\u00D4-\u0303\u0041-\u0309\u0041-\u0301\u0041-\u0300\u0041-\u0323\u0041-\u0303\u0102-\u0309\u0102-\u0301\u0102-\u0300\u0102-\u0323\u0102-\u0303\u00C2-\u0309\u00C2-\u0301\u00C2-\u0300\u00C2-\u0323\u00C2-\u0303]+$)/u
            return pattern.test(value) || 'Invalid name'
          },
          length: v => v.length <= 30 || 'Max length 30 characters',
          notEmpty: v => !!v || 'Required',
        },
        name: {
          characters: value => {
            const pattern = /^(?=[a-zA-Z ]{3,25}$)[A-Za-z]+([\\ A-Za-z]+)*$/
            return pattern.test(value) || 'Enter Display Name between 2-25 characters, start/end with an alpha and only contain alpha words with space.'
          },
          notEmpty: v => !!v || 'Display Name is required',
        },
        email: {
          characters: value => {
            const pattern = /^[A-Za-z]+([A-Za-z0-9]+)@([A-Za-z]+)\.([A-Za-z]+)(.[A-Za-z]+)*$/
            return pattern.test(value) || 'Invalid Email.'
          },
          notEmpty: v => !!v || 'Email is required',
        },
        keyName: {
          notEmpty: v => !!v || 'Required',
        },
      },

      // stuff
      urls: {
        load: '/v1/api/users',

        post: '/v1/api/users/keys',
        attestation: '/v1/users/attestation/result',

        QRsee: '/v1/register/events',

        delete: '/v1/api/corp/users/delete', // :username
      },
    }),

    computed: {
      formAdd () {
        return {
          username: this.newUser.username,
          fullName: this.newUser.fullName,
          keyName: this.newUser.keyName,
        }
      },
    },

    watch: {
      countDown: function (n, o) {
        // console.log('countDown')
        if (n <= 0) {
          this.showQR = false
          this.countDown = 0
        }
      },

      'newUser.regType': function (n, o) {
        // console.log(n, o)
        if (n === 'QRCode') {
          this.newUser.keyName = (this.newUser.username + '_' + 'QR' + '_' + this.rndStr(6)).toUpperCase()
        } else {
          this.newUser.keyName = (this.newUser.username + '_' + this.rndStr(9)).toUpperCase()
        }
      },

      'newUser.username': function (n) {
        if (n !== '' && n !== null && n !== undefined) {
          if (this.newUser.regType === 'QRCode') {
            this.newUser.keyName = (n + '_' + 'QR' + '_' + this.rndStr(6)).toUpperCase()
          } else {
            this.newUser.keyName = (n + '_' + this.rndStr(9)).toUpperCase()
          }
        } else {
          this.newUser.keyName = ''
        }
      },

      addDialog: function (n) {
        if (!n) this.resetForm()
      },
    },

    created () {
      this.loadItems()
    },

    methods: {
      /* __________________________Show Table______________________________ */
      /* __________________________________________________________________ */
      async loadItems () {
        await axios.get(this.urls.load)
          .then((response) => {
            // console.log('response', response)
            this.users = (response.data.data) ? response.data.data : []
            // console.log('load', this.users)
          }).catch(e => {
            this.handleErrors(e)
          }).finally(() => (this.loading = false)) // set loading to false when request finish
      },

      getUsername (item) {
        // console.log('username', item)
        return item.split('@')[0]
      },

      getPermissions (item) {
        // console.log('item', item)
        if (item) {
          const listApps = item.map((i) => {
            return i.appName
          })
          return listApps
        } else return []
      },

      showListAccess (item) {
        if (item.permissions) {
          this.itemPermissions = item.permissions.map((i) => {
            return `${i.appName} (${i.clientID})`
          })
        } else this.itemPermissions = []
      },

      /* ____________________________Add New_______________________________ */
      /* __________________________________________________________________ */
      addUser () {
        this.formHasErrors = false
        Object.keys(this.formAdd).forEach(f => {
          // console.log('key', f, this.$refs[f].validate())
          if (!this.$refs[f].validate()) this.formHasErrors = true
          this.$refs[f].validate(true)
        })
        // console.log('has errors', this.formHasErrors)
        if (this.formHasErrors) return
        this.loading = true
        const data = {
          username: this.newUser.username,
          keyName: this.newUser.keyName,
          regType: this.newUser.regType,
        }
        axios.post(this.urls.post, data)
          .then(response => {
            // console.log('reg response: ', response)
            // const token = response.data.access_token
            // const user = response.data.username
            if (this.newUser.regType === 'FIDO2') {
              this.handleFido2Response(response)
            } else {
              this.handleQrResponse(response)
            }
          })
          .catch(e => {
            this.handleErrors(e)
          }).finally(() => (this.loading = false))
      },

      async handleFido2Response (response) {
        const makeCredChallenge = preformatMakeCredReq(response.data)
        navigator.credentials.create({ publicKey: makeCredChallenge })
          .then((response) => {
            const newCredentialInfo = publicKeyCredentialToJSON(response)
            return axios.post(this.urls.attestation, newCredentialInfo)
          }).then(response => {
            // console.log(response)

            if (response.data.success) {
              this.show_message('success', 'Register key success!')
              this.addDialog = false
              this.resetForm()
              // this.loadItems()
              this.users.push(response.data.data)
              // this.add_cancel()
            }
          }).catch(e => {
            // console.log('123456', e)
            this.handleErrors(e)
          }).finally(this.loading = false)
      },

      async handleQrResponse (response) {
        this.loading = true
        this.imgqr = 'data:image/png;base64,' + response.data.data.img
        this.showQR = true
        this.countDown = 40
        this.countDownTimer()
        localStorageService.setChannel(response.data.data.token)
        this.$sse(`${this.urls.QRsee}/${response.data.data.token}`, { format: 'json' })
          .then(sse => {
            msgServer = sse

            // console.log(sse.getSource())
            sse.onError(e => {
              // console.error('Connection lost', e)
            })

            sse.getSource().onmessage = function (event) {
              // console.log('Message on channel-1: ' + event.data)
            }

            // console.log(sse)

            // Listen for messages without a specified event
            sse.subscribe('', (data, rawEvent) => {
              // console.warn('Received a message w/o an event!', data)
            })

            // Listen for messages based on their event (in this case, "chat")
            sse.subscribe('register', (message, rawEvent) => {
              // console.log(message)
              this.show_message('success', message.keyName + ' register success!')
              this.countDown = 0
              sse.unsubscribe('register')
              sse.close()
              localStorageService.clearChannel()
              this.addDialog = false
              // this.loadItems()
              this.users.push(message)
              this.resetForm()
            })

            // Unsubscribes from event-less messages after 7 seconds
            setTimeout(() => {
              sse.unsubscribe('')

              // console.log('Stopped listening to event-less messages!')
            }, 7000)

            // Unsubscribes from chat messages after 7 seconds
            setTimeout(() => {
              sse.unsubscribe('register')

              // console.log('Stopped listening to register messages!')
              sse.close()
              localStorageService.clearChannel()
            }, 40000)
          }).catch(e => {
            this.handleErrors('QR service not available!')
          }).finally(this.loading = false)
        // storing jwt in localStorage. https cookie is safer place to store
        // localStorage.setItem('token', token)
        // localStorage.setItem('user', user)
        // axios.defaults.headers.common.Authorization = 'Bearer ' + token
        // mutation to change state properties to the values passed along
        // commit('auth_success', { token, user })
        // resolve(response)
      },

      async cancelQR () {
        // console.log('handleQRCancel')
        this.countDown = 0
        const channel = localStorageService.getChannel()
        if (channel) {
          await axios.get(`${this.urls.QRsee}/${channel}/canceladd`)
            .then(sse => {
              // console.log(sse)
              msgServer.close()
              localStorageService.clearChannel()
            }).catch(() => {
              this.show_message('error', 'No channel to cancel')
            })
        }
      },

      /* ___________________________User Detail____________________________ */
      /* __________________________________________________________________ */
      toUserDetail (item) {
        this.$store.commit('SET_PICKED_CHILD', item.username)
        this.$router.push('/configs/keys')
      },

      /* _____________________________Delete_______________________________ */
      /* __________________________________________________________________ */
      deleteUser (item) {
        this.$swal.fire({
          titleText: `Are you sure you want to delete ${item.username}?`,
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#dd3333',
          confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
          if (result.value) {
            this.delete_agree(item)
            // this.delete_dialog = true
          }
        })
      },

      async delete_agree (item) {
        this.loading = true // the loading begin
        const index = this.users.indexOf(item)
        const username = item.username.split('@')[0]
        await axios.delete(`${this.urls.delete}/${username}`).then(response => {
          // console.log('delete response', response)
          this.users.splice(index, 1)

          this.$swal.fire({
            titleText: `User ${item.username} has been deleted`,
            icon: 'success',
            showCancelButton: false,
          })
        }).catch(e => {
          this.handleErrors(e)
        }).finally(() => (this.loading = false)) // set loading to false when request finish
      },

      /* ____________________________Helpers_______________________________ */
      /* __________________________________________________________________ */
      close () {
        this.addDialog = false
        this.resetForm()
      },

      resetForm () {
        this.formHasErrors = false
        Object.keys(this.formAdd).forEach(f => {
          this.$refs[f].reset()
        })
      },

      countDownTimer () {
        // console.log('countDownTimer')
        if (this.countDown > 0) {
          setTimeout(() => {
            this.countDown -= 1
            this.countDownTimer()
          }, 1000)
        }
      },

      rndStr (len) {
        // console.log('rndStr')
        let text = ''
        const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'

        for (let i = 0; i < len; i++) {
          text += chars.charAt(Math.floor(Math.random() * chars.length))
        }

        const rightNow = new Date()
        text = rightNow.toISOString().slice(0, 10).replace(/-/g, '') + '_' + text
        return text
      },

      handleErrors (error) {
        if (error.response === undefined) {
          this.show_message('error', error.toString())
        } else if (!error.response.data.success) {
          if (error.response.data.errorMessage) {
            this.show_message('error', error.response.data.errorMessage)
          } else if (error.response.data.validations) {
            this.show_message('error', error.response.data.validations[0].message)
          } else {
            this.show_message('error', error.response.data.message)
          }
        } else {
          this.show_message('error', 'api service not available!')
        }
      },

      show_message (type, text) {
        // type = 'success', 'info', 'error' ... color string
        this.$swal.fire({
          icon: type,
          titleText: type,
          text: text,
          timer: 5000,
        })
      },
    },
  }
</script>

<style lang="sass">

#users
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>th, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>th, .v-data-table>.v-data-table__wrapper>table>thead>tr>th
    font-size: .85rem !important

  .v-message
    min-height: 0 !important

  .dialog-btn
    margin: 0px

/*  .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
    margin: 0 12px 0 12px !important

/*
  .v-card__actions
    padding: 8px 24px 8px 24px !important
  .v-card.v-card__text
    padding: 8px 24px 8px 24px !important

  .v-card.v-card__action
    padding: 8px 24px 8px 24px !important

/* for combobox
  .v-text-field.v-text-field--enclosed .v-text-field__details
    margin-bottom: 0px !important
    height: 0 !important
  .v-input__slot
    margin-bottom: 0px !important
  .v-text-field__details
    min-height: 0 !important

</style>
