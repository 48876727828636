var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"users","fluid":"","tag":"section"}},[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1):_vm._e(),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"indigo","icon":"mdi-account-details","inline":"","hover-reveal":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light mt-2"},[_vm._v(" User List ")])]},proxy:true}])},[_c('v-row',{staticClass:"align-center"},[_c('v-spacer'),_c('v-text-field',{staticClass:"ml-auto",staticStyle:{"max-width":"250px","margin-bottom":"5px","margin-right":"12px"},attrs:{"append-icon":"mdi-magnify","label":"Search","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"mx-3",attrs:{"color":"primary"},on:{"click":function($event){_vm.addDialog = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-plus ")]),_vm._v(" Add User ")],1)],1),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.search,"multi-sort":"","sort-desc":""},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUsername(item.username))+" ")]}},{key:"item.permissions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"open-on-click":"","bottom":"","offset-y":"","allow-overflow":"","max-height":"304px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-items: justify-center",attrs:{"text":"","min-width":"100%"},on:{"click":function($event){return _vm.showListAccess(item)}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getPermissions(item.permissions).join(', ').slice(0, 50))+" "),_c('v-spacer'),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.itemPermissions),function(p,index){return _c('v-list-item',{key:index,attrs:{"link":""}},[_c('v-list-item-title',[_vm._v(_vm._s(p))])],1)}),1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"green","outlined":"","fab":"","x-small":""},on:{"click":function($event){return _vm.toUserDetail(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted-square")])],1)]}}],null,true)},[_c('span',[_vm._v("Key list")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"outlined":"","fab":"","x-small":""},on:{"click":function($event){return _vm.deleteUser(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete user")])])]}}])})],1),[_c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Add User")])]),_c('v-card-text',[_c('v-text-field',{ref:"username",attrs:{"label":"Username *","prepend-icon":"mdi-account-outline","rules":[_vm.rules.username.notEmpty, _vm.rules.username.characters],"counter":20},model:{value:(_vm.newUser.username),callback:function ($$v) {_vm.$set(_vm.newUser, "username", $$v)},expression:"newUser.username"}}),_c('v-text-field',{ref:"fullName",attrs:{"label":"Full Name *","prepend-icon":"mdi-account-tie-outline","rules":[_vm.rules.fullName.notEmpty, _vm.rules.fullName.characters, _vm.rules.fullName.length],"counter":30},model:{value:(_vm.newUser.fullName),callback:function ($$v) {_vm.$set(_vm.newUser, "fullName", $$v)},expression:"newUser.fullName"}}),_c('v-text-field',{ref:"keyName",attrs:{"label":"Key Name *","prepend-icon":"mdi-key-outline","rules":[_vm.rules.keyName.notEmpty],"counter":40},model:{value:(_vm.newUser.keyName),callback:function ($$v) {_vm.$set(_vm.newUser, "keyName", $$v)},expression:"newUser.keyName"}}),_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.newUser.regType),callback:function ($$v) {_vm.$set(_vm.newUser, "regType", $$v)},expression:"newUser.regType"}},[_c('v-radio',{attrs:{"label":"FIDO2 Key register","value":"FIDO2"}}),_c('v-radio',{attrs:{"label":"QR register","value":"QRCode"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-slide-x-reverse-transition',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-0",attrs:{"icon":""},on:{"click":_vm.resetForm}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Reset")])])],1),_c('v-btn',{attrs:{"color":"blue darken-1","width":"100"},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","width":"100"},on:{"click":_vm.addUser}},[_vm._v(" Add ")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"350px"},model:{value:(_vm.showQR),callback:function ($$v) {_vm.showQR=$$v},expression:"showQR"}},[_c('v-card',{attrs:{"color":"rgba(255, 255, 255)","outlined":""}},[_c('v-card-text',{staticStyle:{"color":"#333333"}},[_c('div',{staticClass:"text-h3 subtitle-1 font-weight-light text-center"},[_vm._v(" Scan the QR code ")]),_c('v-img',{attrs:{"src":_vm.imgqr}}),_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"md":"4","sm":"4","lg":"4"}}),_c('v-col',{attrs:{"md":"4","sm":"4","lg":"4"}},[_c('v-btn',{attrs:{"width":"100%","color":"success"},on:{"click":_vm.cancelQR}},[_vm._v(" Cancel "+_vm._s(_vm.countDown)+" ")])],1),_c('v-col',{attrs:{"md":"4","sm":"4","lg":"4"}})],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }